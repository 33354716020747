<template>
    <div class="render-gallery__variant col-sm-6 col-md-4 col-lg-3">
        <a href @click.prevent="click"><img
            :src="variant.thumbs.md"
            class="w-100"
            title="Click for expanded view and download..."
        ></a>
        <!--<div>
            <a :href="variant.detail">Download PDF</a>
        </div>-->
    </div>
</template>
<script>
export default {
    name: 'ArtworkVariant',
    components: {},
    props: {
        variant: Object
    },
    methods: {
        click: function() {
            this.$emit('artwork-variant:thumb-click', this.data);
        }
    }
};
</script>
