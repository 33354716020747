class GTag {
    constructor() {

    }
    onThumbClick(artwork, variant) {
        if (window.gtag) {
            let payload = {
                'artwork_code_variant': `${artwork.code}__${variant.code}`,
                'artwork_code': artwork.code,
                'artwork_variant': variant.code
            };
            window.gtag('event', 'view_item', payload);
        }
    }
    onVariantDownload(artwork, variant, dimensions) {
        if (window.gtag) {
            let payload = {
                'artwork_code_variant': `${artwork.code}__${variant.code}`,
                'artwork_code': artwork.code,
                'artwork_variant': variant.code,
                'artwork_dimensions': dimensions
            };
            window.gtag('event', 'file_download', payload);
        }
    }
}

export default new GTag();
