const artworks = {
    "overlapping-circles-2021-08": {
        "code": "overlapping-circles-2021-08",
        "title": "Circles August 2021",
        "variants": [
            "410",
            "032",
            "068",
            "083",
            "201",
            "210",
            "213",
            "355",
            "382",
            "409",
            "763",
            "765"
        ]
    },
    "clustered-circles-2022-01": {
        "code": "clustered-circles-2022-01",
        "title": "Circles January 2022",
        "variants": [
            "0017",
            "0023",
            "0117",
            "1361",
            "1499",
            "1551",
            "1577",
            "1721",
            "1750",
            "1793",
            "1865",
            "2051"
        ]
    }
};

const artworkGalleryDefaults = {
    "urlStub": "https://circularplots.com/renders/{code}",
    "variants": {
        "thumbs": {
            "sm": "{urlStub}/{code}--{variant}--300.jpg",
            "md": "{urlStub}/{code}--{variant}--500.jpg",
            "lg": "{urlStub}/{code}--{variant}--1000.jpg"
        },
        "detail": "{urlStub}/{code}--{variant}.pdf",
        "pdfs": [
            "{urlStub}/{code}--{variant}--10inx8in.pdf",
            "{urlStub}/{code}--{variant}--11inx8.5in.pdf",
            "{urlStub}/{code}--{variant}--14inx11in.pdf",
            "{urlStub}/{code}--{variant}--20inx16in.pdf"
        ]
    }
};

class API {
    constructor() {

    }
    static ntrplt(str, context) {
        Object.keys(context).forEach(k => {
            str = str.replace(new RegExp(`\\{${k}\\}`, 'g'), context[k]);
        });
        return str;
    }
    artworks() {
        return Promise.resolve(Object.keys(artworks).map(code => {
            let artwork = JSON.parse(JSON.stringify(artworks[code]));
            this.artworkProcessVariants(artwork);
            return artwork;
        }));
    }
    artworkProcessVariants(artwork) {
        let code = artwork.code;
        artwork.variants.forEach((variant, variantIdx) => {
            if (typeof variant == 'string') {
                variant = {
                    code: variant
                };
            }
            let context = {
                urlStub: API.ntrplt(artworkGalleryDefaults.urlStub, { code: code }),
                code: code,
                variant: variant.code
            };
            if (!variant.thumbs) {
                variant.thumbs = {};
            }
            if (!variant.detail) {
                variant.detail = API.ntrplt(artworkGalleryDefaults.variants.detail, context);
            }
            Object.keys(artworkGalleryDefaults.variants.thumbs).forEach(thumbCode => {
                if (!variant.thumbs[thumbCode]) {
                    variant.thumbs[thumbCode] = API.ntrplt(artworkGalleryDefaults.variants.thumbs[thumbCode], context);
                }
            });

            if (!variant.pdfs) {
                variant.pdfs = artworkGalleryDefaults.variants.pdfs.map(pdfTpl => {
                    return {
                        url: API.ntrplt(pdfTpl, context)
                    }
                });
            }
            variant.pdfs.forEach(pdf => {
                let m = pdf.url.match(/--([0-9.]+)inx([0-9.]+)in\.[^.]+$/);
                if (m) {
                    pdf.dimensions = `${m[1]}in x ${m[2]}in`
                }
            });
            artwork.variants[variantIdx] = variant;
        });
        return artwork;
    }
    artwork(code) {
        let artwork = artworks[code];
        this.artworkProcessVariants(artwork);
        return Promise.resolve(artwork);
    }
}

export default new API();
