<template>
    <div class="home container-fluid">
        <div v-if="artworks" class="row">
            <div
                v-for="artwork in artworks"
                :key="artwork.code"
                class="my-3 col-12"
            >
                <strong>{{artwork.title}}</strong>
                <Artwork
                    :code="artwork.code"
                ></Artwork>
                <!--
                <div class="m-3">
                    <router-link :to="`/artwork/${artwork.code}`">
                        <img
                            class="w-100"
                            :src="artwork.variants[0].thumbs.lg"
                        >
                    </router-link>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/services/api.js';
import Artwork from '@/components/Artwork.vue';

export default {
    name: 'Home',
    components: {
        Artwork
    },
    data: function() {
        return {
            artworks: null
        }
    },
    mounted: function() {
        api.artworks().then((artworks) => {
            this.artworks = artworks;
        });
    }
}
</script>
