import Vue from 'vue'
//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// Import Bootstrap an BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/main.scss'

// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue, {
//  EnableNegativeMargins: true
//});
// Optionally install the BootstrapVue icon components plugin
//Vue.use(IconsPlugin)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
