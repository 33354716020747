<template>
    <div class="artwork">
        <div class="container render-gallery__variants">
            <div class="row" v-if="artwork && artwork.variants">
                <ArtworkVariant
                    v-for="variant in artwork.variants"
                    :key="variant.code"
                    :variant="variant"
                    @artwork-variant:thumb-click="onThumbClick(variant)"
                />
            </div>
        </div>
        <div class="modal fade modal--render-detail" :ref="variantJumbotronRef" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content modal--render-detail__modal-content">
                    <div class="modal--render-detail__dismiss-button-container">
                        <button type="button" class="btn-close" @click="variantModal.hide()" aria-label="Close"></button>
                    </div>
                    <div class="modal-body modal--render-detail__modal-body">
                        <div v-if="variantJumbotron">
                            <img
                                :src="variantJumbotron.thumbs.lg"
                                class="w-100"
                                style="max-width: 1000px;"
                            >
                            <div class="dropdown mt-1">
                                <a
                                    class="btn btn-secondary dropdown-toggle"
                                    href
                                    role="button"
                                    :id="`variant-jumbotron-download-dropdown--${variantJumbotron.code}`"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Download PDF
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li
                                        v-for="pdf in variantJumbotron.pdfs"
                                        :key="pdf.url"
                                    >
                                        <a
                                            class="dropdown-item"
                                            :href="pdf.url"
                                            target="_blank"
                                            @click="onVariantDownload(variantJumbotron, pdf.dimensions)"
                                        >
                                            {{pdf.dimensions}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .render-gallery__variants {
        margin-top: 0;
    }
    .render-gallery__variants .render-gallery__variant {
        margin-top: 16px;
    }
</style>
<script>
import api from '@/services/api.js';
import gtag from '@/services/gtag.js';
import { Modal } from 'bootstrap';
import ArtworkVariant from '@/components/ArtworkVariant.vue';

export default {
    name: 'Artwork',
    components: {
        ArtworkVariant
    },
    props: {
        code: String
    },
    data: function() {
        return {
            //code: this.$route.params.code,
            artwork: null,
            state: 'init',
            variantJumbotron: null,
            variantJumbotronRef: `artwork-modal--${String(Math.random()).replace(/\./g, '')}`,
            variantModal: null
        };
    },
    mounted: function() {
        api.artwork(this.code).then((artwork) => {
            this.artwork = artwork;
        });
    },
    methods: {
        onThumbClick: function(variant) {
            gtag.onThumbClick(this.artwork, variant);
            this.variantJumbotron = variant;
            this.variantModal = new Modal(this.$refs[this.variantJumbotronRef]);
            this.variantModal.show();
        },
        onVariantDownload: function(variant, dimensions) {
            gtag.onVariantDownload(this.artwork, variant, dimensions);
        }
    }
};
</script>